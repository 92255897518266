//INFO: 공지사항 게시판 등록
<script>
import CustomRadioVue from '@/components/ui/CustomRadio.vue'
import TextEditor from '@/components/TextEditor.vue' // TextEditor 불러오기
import { saveNotice, updateNotice, readNotice } from '@/api/admin/notice' // 공지사항 저장, 수정, 조회
import store from '@/store'

export default {
  name: 'adminBoardNoticeCreate',
  components: { TextEditor, CustomRadioVue },
  data() {
    // 공지사항 게시글 번호
    const boardNo = Number(this.$route.params.boardNo)

    const boardData = {
      boardName: 'notice',
      boardId: boardNo
    }

    return {
      boardNo: boardNo,
      noticePageNo: 0,
      boardData: boardData,
      noticeData: {},
      noticeType: 0,
      noticeTitle: '',
      noticeContent: ''
    }
  },
  create() {},
  async mounted() {
    this.noticePageNo = Number(this.$route.params.page) // 공지사항 게시판 페이지 번호

    // 로그인한 사용자 정보
    const user = store.getters['userStore/info'].name
    this.user = user

    this.noticeData = await readNotice(this.boardNo)

    if (this.noticeData.code === 'DB_DATA_NOT_FOUND') return

    this.noticeTitle = this.noticeData.title
    this.noticeContent = this.noticeData.content
    this.noticeType = this.noticeData.noticeType
  },
  methods: {
    setNoticeType(e) {
      this.noticeType = e.target.value
    },
    // ********** 공지사항 수정 **********
    async update(data, boardNo) {
      const obj = {
        noticeType: Number(this.noticeType),
        title: data.title,
        content: await data.getHtmlAfterSaveImages(this.boardData),
        updatedAt: new Date().toLocaleDateString()
      }

      return await updateNotice(boardNo, obj)
    },

    // ********** 공지사항 추가 **********
    async insert(data) {
      const saveNoticeData = {
        noticeType: Number(this.noticeType),
        userId: this.user, // 작성자
        labNo: 0, // 연구실 번호
        title: data.title, // 공지사항 제목
        content: ' ', // 내용
        createdAt: new Date().toLocaleDateString(), // 작성일
        updatedAt: '' // 수정일
      }

      const result = await saveNotice(saveNoticeData)

      if (result?.code) return result

      this.boardData.boardId = result.noticeId

      const obj = {
        noticeType: Number(this.noticeType),
        title: data.title,
        content: await data.getHtmlAfterSaveImages(this.boardData),
        updatedAt: new Date().toLocaleDateString()
      }

      return await updateNotice(result.noticeId, obj)
    },

    // ********** 답글 저장 **********
    // textEditor의 저장버튼을 숨겨두고 refs를 통해서 textEditor의 저장함수 saveContent를 불러온다.
    saveNoticeBtn() {
      const editor = this.$refs.editor
      editor.saveContent()
    },

    // ********** 공지사항 저장 **********
    async emitData({ getHtmlAfterSaveImages }) {
      const data = {
        title: this.noticeTitle,
        getHtmlAfterSaveImages
      }

      const result = this.boardNo !== 0 ? await this.update(data, this.boardNo) : await this.insert(data)
      if (result?.code) return alert(result.message)

      alert('공지사항이 저장되었습니다.')
      this.goBoardList()
    },
    // ********** 목록 버튼 **********
    goBoardList() {
      this.$router.push({
        path: `/admin/board/notice/${this.noticePageNo}`
      })
    }
  }
}
</script>

<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <h4>공지사항 글쓰기</h4>
  </nav>

  <div id="content-wrapper" class="d-flex flex-column">
    <div class="btnArea">
      <button class="e-btn wf__btn btn-primary" @click="goBoardList()">목록</button>
      <button class="e-btn wf__btn btn-success ml-2" @click="saveNoticeBtn()">저장</button>
    </div>

    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="90%" />
        </colgroup>

        <tbody>
          <tr>
            <th></th>
            <td>
              <div class="radio-wrapper">
                <CustomRadioVue
                  label="공지사항"
                  name="noticeType"
                  :value="0"
                  :checked="noticeType === 0"
                  :disabled="boardNo"
                  @onChecked="setNoticeType"
                ></CustomRadioVue>
                <CustomRadioVue
                  label="대학별뉴스"
                  name="noticeType"
                  :value="1"
                  :checked="noticeType === 1"
                  :disabled="boardNo"
                  @onChecked="setNoticeType"
                ></CustomRadioVue>
                <CustomRadioVue
                  label="유망연구실 뉴스"
                  name="noticeType"
                  :value="2"
                  :checked="noticeType === 2"
                  :disabled="boardNo"
                  @onChecked="setNoticeType"
                ></CustomRadioVue>
              </div>
            </td>
          </tr>

          <tr>
            <th>제목</th>
            <td>
              <input class="" v-if="!boardNo || noticeData.title" v-model="noticeTitle" placeholder="제목을 입력해주세요" size="50" />
            </td>
          </tr>

          <tr>
            <th></th>
            <td>
              <text-editor
                v-if="!boardNo || noticeData.content"
                class="text-editor"
                :propsData="noticeContent"
                @emitData="emitData"
                :boardData="boardData"
                :hideSave="true"
                height="550px"
                ref="editor"
              >
              </text-editor>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="container-fluid">
      <!-- 버튼 구역 -->
    </div>
  </div>
</template>

<style scoped>
#content-wrapper {
  max-width: 1280px;
}
.btnArea {
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.radio-wrapper {
  display: flex;
}
.text-editor {
  margin: 0 !important;
  max-width: none !important;
}
</style>
